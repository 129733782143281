<template>
    <Dialog :header='accountTypeLabels?.header' v-model:visible="display" :modal="true" class='add-paper-account-modal' @hide="onHide">         
        <span v-show="accountTypeOption">{{accountTypeLabels?.description}}</span>

        <InputText class='account-name-input' type="text" v-model="accountName" :placeholder="accountNamePlaceholder"/>
        <Dropdown class="currency-type-dropdown" v-model="currencyTypeOption" :options="currencyTypeOptions" optionLabel='name' :placeholder="currencyTypeDropdownPlaceholder"/>
        <template #footer>
            <Button label="Cancel" class='cancel-button red-button__secondary' @click="onClose" v-if='fromPlaceTradeOrderModal'/>
			<Button label="Create" class='add-account-button green-button__primary' @click="addAccount" :disabled="disableAddAccountButton" />
		</template>
         
	</Dialog>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';

import PortfolioService from '../../../service/PortfolioService';

import {PAPER_ANALYSIS_ACCOUNT_TYPE, PAPER_TRADING_ACCOUNT_TYPE, CANADIAN_CURRENCY_TYPE, US_CURRENCY_TYPE} from '../../../common/constants';

import EventBus from '../../../event-bus';

const ACCOUNT_TYPE_LABELS = {
    paperAnalysis: {
        header: "Add a Manual account",
        description: "Manual accounts are an alternative to connecting your brokerage.  You can replicate your portfolio by entering your holdings manually."
    },
    paperTrading: {
        header: "Add a Paper Trading Account",
        description: "Paper Trading accounts allow you to place buy/sell orders for stocks using 'Playdough'. The accounts are designed to mimick as much as possible real trading accounts."
    },
};

export default {
    name: 'AddPaperAccountModal',
    emits: ['created-account', 'created-first-account'],
    components: {
        Dialog,
        Dropdown,
        
    },

    computed: {
        hasPaperAccount() {
            return this.paperAccounts.length > 0;
        },
        disableAddAccountButton() {
            return !this.accountTypeOption || !this.accountName || !this.currencyTypeOption; 
        },
    },

    data() {
        return {
            ACCOUNT_TYPE_LABELS,
            
            accountTypeLabels: ACCOUNT_TYPE_LABELS.paperTrading,

            display: false,
            fromPlaceTradeOrderModal: false,
            fromBuySellButton: false,

            accountName: null,
            accountNamePlaceholder: 'Enter in a nickname',

            accountTypeOption: null,

            currencyTypeOption: null,
            currencyTypeOptions: [{id: CANADIAN_CURRENCY_TYPE['ID'], name: CANADIAN_CURRENCY_TYPE['NAME']}, {id: US_CURRENCY_TYPE['ID'], name: US_CURRENCY_TYPE['NAME']}],
            currencyTypeDropdownPlaceholder: 'Select a Currency'
        }
    },


    methods: {
        open(accountTypeId) {
            if (this.fromPlaceTradeOrderModal 
                || this.fromBuySellButton 
                || PAPER_TRADING_ACCOUNT_TYPE["ID"] == accountTypeId){
                this.accountTypeOption = PAPER_TRADING_ACCOUNT_TYPE['NAME'];
                this.accountTypeLabels = ACCOUNT_TYPE_LABELS.paperTrading;
            } else {
                this.accountTypeOption = PAPER_ANALYSIS_ACCOUNT_TYPE['NAME'];
                this.accountTypeLabels = ACCOUNT_TYPE_LABELS.paperAnalysis;
            }

            this.display = true;
        },

        openFromPlaceTradeOrderModal() {
            this.fromPlaceTradeOrderModal = true;
            this.open();
        },

        openFromBuySellButton() {
            this.fromBuySellButton = true;
            this.open();
        },

        onClose() {
            this.display = false;
        },

        onHide() {
            this.currencyTypeOption = null;
            this.accountName = null;
            this.accountTypeOption = null;
            this.fromPlaceTradeOrderModal = false;
            this.fromBuySellButton = false;
        },

        addAccount() {
            if (this.accountTypeOption === PAPER_ANALYSIS_ACCOUNT_TYPE['NAME']) {

                PortfolioService.createPaperAnalysisAccount(this.accountName, this.currencyTypeOption.id).then(resp => {
                    if (resp.data.status === 'success') {
                        this.$toast.add({severity: 'success', summary: 'Manual account created!', life: 2500, group: 'center'});

                        const data = {
                            account: resp.data.account
                        }

                        this.$emit('created-account', data);

                        EventBus.emit('track-create-paper-analysis-account');
                    } else {
                        if( resp.data.message == 'Limit Reached') {
                            this.$toast.add({ severity: 'error', summary: `Manual Account limit reached.`, detail: `There is a limit of `+ resp.data.limit+` free Manual Accounts.  <br><br>Consider upgrading to <a href="/index.html#/billing">Premium Portfolio Tools</a> to get unlimited Manual Accounts.`,  group: 'center-html'});
                        }
                        else {
                            console.error('error creating Manual account: ', + resp.data.message);
                            this.$toast.add({ severity: 'error', summary: `There was an error creating your Manual account. Please try again later.  If the problem persist please contact support@invrs.com`, life: 2500, group: 'center'});
                        }
                    }

                    this.onClose();
                });
            } else if (this.accountTypeOption === PAPER_TRADING_ACCOUNT_TYPE['NAME']) {
                PortfolioService.createPaperTradingAccount(this.accountName, this.currencyTypeOption.id).then(resp => {
                    if (resp.data.status === 'success') {
                        this.$toast.add({severity: 'success', summary: 'Paper Trading account created!', life: 2500, group: 'center'});

                        const data = {
                            account: resp.data.account,
                            openPlaceTradeOrderModal: this.fromPlaceTradeOrderModal || this.fromBuySellButton
                        }

                        this.$emit('created-account', data);

                        EventBus.emit('track-create-paper-trading-account');
                    } else {
                        if( resp.data.message == 'Limit Reached') {
                            this.$toast.add({ severity: 'error', summary: `Paper Trading Account limit reached.`, detail: `There is a limit of `+ resp.data.limit+` free Paper Trading Accounts.  <br><br>Consider upgrading to <a href="/index.html#/billing">Premium Portfolio Tools</a> to get unlimited Paper Trading Accounts.`,  group: 'center-html'});
                        }
                        else {
                            console.error('error creating Paper Trading account: ', + resp.data.message);
                            this.$toast.add({ severity: 'error', summary: `There was an error creating your Paper Trading account. Please try again later.  If the problem persist please contact support@invrs.com`, life: 2500, group: 'center'});
                        }
                    }

                    this.onClose();
                })
            }
        }
    }
}
</script>

<style>
.add-paper-account-modal {
    width: 420px;
}

.add-paper-account-modal .p-dialog-header {
    padding: 16px;
}

.add-paper-account-modal .p-dialog-content {
    padding: 0px 16px;
    overflow-y: visible;
    display: flex;
    flex-direction: column;
}
.add-paper-account-modal .p-dialog-content > * {
    margin-bottom: 12px;
}
.add-paper-account-modal .p-dialog-content > *:last-child {
    margin-bottom: 0px;
}

.add-paper-account-modal .p-dialog-footer {
    padding: 16px;
    justify-content: center;
    display: flex;
    align-items: center;
}
.add-paper-account-modal .p-dialog-footer > .p-button {
    width: 50%;
}

@media (max-width: 760px) {
    .add-paper-account-modal { 
        left: 0!important;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
        border-radius: 0;
    }

    .add-paper-account-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .add-paper-account-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .add-paper-account-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    /** needed to avoid mobile browsers zooming in when entering text */
    select:focus,
    textarea:focus,
    input:focus {
    font-size: 16px;
    background: #eee;
  }
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.body-text {
    color: black;
    text-align: center;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.p-button {
    border-radius: 50px;
    padding: 8px 64px;
    font-size: 18px;
    justify-content: center;
}

.p-dropdown {
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
}
.p-dropdown:hover, .p-dropdown:active {
    border-color: #33CC99;
}
.p-dropdown:focus, .p-dropdown:not(.p-disabled).p-focus, .p-inputwrapper-focus {
    border-color: #33CC99;
    box-shadow: none;
}
::v-deep(.p-dropdown-label) {
    padding: 0px;
    font-size: 1rem;
    color: black;
}
::v-deep(.p-dropdown-label.p-placeholder) {
    color: #BFBFBF;
    padding: 0px;
}
::v-deep(.p-dropdown-item) {
    color: #32364e;
    border-bottom: 1px solid #333;
}

.account-name-input {
    border-radius: 10px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    width: 100%;
    margin-right: 16px;
    padding: 8px 0px 8px 16px;
    color: black;
}
.account-name-input::placeholder {
    color: #BFBFBF;
}
.account-name-input:hover {
    border-color: #33CC99;
}
.account-name-input:focus {
    box-shadow: none;
    border-color: #33CC99;
}

::v-deep(.account-type) {
    color: #32364e;
    font-weight: bold;
}
</style>