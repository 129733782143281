<template>
    <div class="card">
      <div class="header-container">
        <h3 class="header">Cash Flow Table</h3>
        <i class="pi pi-info-circle" v-tooltip.right="'This table shows cash-flow details for all of the non-cash positions in your investment account. All values are converted to the account currency.'"></i>
      </div>
        <DataTable v-if="userHasHoldings" :value="holdings" :scrollable="true" scrollHeight="500px" :rowClass="portfolioRowClass" :resizableColumns="true" columnResizeMode="expand">
          <Column header="Actions" headerClass="holdings-header" class="action-column" v-if="!isMobile" :frozen="true"> 
            <template #body="slotProps">
              <Button class='purple-button__secondary view-score-details-button' icon='pi pi-info-circle' @click='openScoreDetailsModal(slotProps.data)' 
              v-if="$store.getters['featureSettings'].premiumSubscriptionEnabled" v-tooltip.bottom="{value: 'View Score Details'}" :disabled="!slotProps.data.dividendSafetyFactors"/>
            </template>
          </Column>
          <Column field="symbol" header="Symbol" headerClass="holdings-header" class="symbol-column">
            <template #body="slotProps">
              <div class="ticker">
                {{slotProps.data.symbol}}
              </div>
            </template>
          </Column>
          <Column field="name" header="Name" headerClass="holdings-header" class="name-column">
            <template #body="slotProps">
              <div class="company">
                {{slotProps.data.name}}
              </div>
            </template>
          </Column>
          <Column field="divYield" header="Dividend Yield" headerClass="holdings-header" class="yield-column">
            <template #body="slotProps">
              <div class="yield">
                {{ valueFormatter(slotProps.data.divYield, 'percentage')}}
              </div>
            </template>
          </Column>
          <Column field="yieldOnCost" header="Yield on Cost" headerClass="holdings-header" class="yield-column">
            <template #body="slotProps">
              <div class="yield">
                {{ valueFormatter(slotProps.data.yieldOnCost, 'percentage')}}
              </div>
            </template>
          </Column>
          <Column field="annualIncome" header="Annual Income" headerClass="holdings-header" class="income-column">
            <template #body="slotProps">
              <div class="income">
                {{valueFormatter(slotProps.data.annualIncome, 'currency')}}
              </div>
            </template>
          </Column>
          <Column field="dividendSafety" header="Dividend Safety" headerClass="holdings-header" class="safety-column">
            <template #body="slotProps">
              <div :class="valueClass(slotProps.data)">
                {{slotProps.data.safety}}
              </div>
            </template>
          </Column>
        </DataTable>

        <ScoreDetailsModal ref="scoreDetailsModal"/>
        <UpgradeToPremiumModal ref='upgradeToPremiumModal' :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText='bodyText' />
      </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import ScoreDetailsModal from '../modal/ScoreDetailsModal.vue';
import UpgradeToPremiumModal from '../../modal/UpgradeToPremiumModal.vue';

import { PREMIUM_SUBSCRIPTION_TYPES } from '../../../common/constants';
import BrowserUtils from '../../../utilities/BrowserUtils';
import UserUtils from '../../../utilities/UserUtils';

export default {
  name: 'CashFlowTable',
  
  components: {
    DataTable,
    Column,

    ScoreDetailsModal,
    UpgradeToPremiumModal
  },

  props: {
    holdings: {
      required: true,
    }
  },

  data() {
    return {
      //CONSTANTS
      PREMIUM_SUBSCRIPTION_TYPES,

      bodyText: "To get access to the score detials of your cashflow plus all the other premium portfolio tools, upgrade to premium and lock in the current low price for as long as you keep your subscription active.",
    }
  },
  computed: {
    userHasHoldings() {
      return this.holdings.length > 0;
    },
    
    isMobile() {
      return BrowserUtils.isMobile();
    },

  },

  methods: {

    openScoreDetailsModal(data) {
      if (UserUtils.isPremiumSubscriber()) {
        let scoreDetailsData = {
          ...data.dividendSafetyFactors
        }

        scoreDetailsData.score = data.safety;

        this.$refs.scoreDetailsModal.open(scoreDetailsData);
      } else {
        this.$refs.upgradeToPremiumModal.open();
      }
    },

    valueClass(data) {
      return [
        {
          'high': data.safety === 'HIGH',
          'medium': data.safety === 'MEDIUM',
          'low': data.safety === 'LOW',
        }
      ];
    },

    portfolioRowClass() {
      return 'portfolio-row';
    },

    valueFormatter(value, type) {
      if (type === 'currency') {
        if( value ) {
          const formattedValue = '$' + value.toLocaleString('en-US', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2
          });
          return formattedValue;
        }else {
          return '-';
        }
      } else if (type === 'percentage') {
        if( value ) {
          const formattedValue = value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + '%';
          return formattedValue;
        }
        else {
          return '-';
        }
      }
    }
  }
}
</script>

<style scoped>
.header-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.header {
  font-size: 24px;
  color: #32364e;
  margin-bottom: 0px;
  margin-right: 8px;
}

::v-deep(.portfolio-row) {
  font-family: 'Trebuchet MS';
}

::v-deep(.holdings-header), ::v-deep(.p-column-title) {
  font-family: 'Trebuchet MS';
  font-weight: 400;
  font-size: 16px;
  background: #fff !important;
  color: #999;
}

::v-deep(.weight-column), ::v-deep(.symbol-column) {
  min-width: 100px;
  max-width: 100px;
}

::v-deep(.name-column) {
  min-width: 300px;
}

.ticker, .company, .yield, .income, .high, .medium, .low {
  font-size: 16px;
}

.high, .medium, .low {
  padding: 3px 6px;
  border-radius: 4px;
  color: black;
  font-size: 14px;
}

.high {
  background-color: rgb(51, 204, 153);
}

.medium {
  background-color: rgb(255, 189, 83);
}

.low {
  background-color: rgb(230, 62, 62);
  color: #fff;
}

.ticker {
  color: #32364e;
  font-weight: bold;
}

.company, .yield, .income {
  color: black;
}

.view-score-details-button {
  border-radius: 50%;
}
</style>