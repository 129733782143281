<template>
    <div class="tab-content">
        <LoadingSpinner :showLoading="!acctsLoaded" style="width: 100%; height: 100%" />
        <div class="p-grid" v-show="acctsLoaded">
            

            <div class="card selected-account-card">
                <div class='grid-header'>
                    Account Details
                    <img src="/assets/images/cash-account.png" />
                </div>
                <div class='p-grid'>
                    <div class='p-md-12' style="width:100%;">
                        <table >
                            <tr v-for="acct in cashAccounts" :key="acct.investmentAccountId">
                                
                                <td><p>
                                    <template  v-if="!isMobile()">
                                       Playdough {{acct.name}}
                                    </template>
                                    <template v-else>
                                        Playdough {{ (acct.currencyId == '1' ? 'CAD' : 'USD')}}
                                    </template>    
                                    </p></td>
                                <td ><p>Balance:</p></td>
                                <td ><p>{{formatMoney(acct.totalValue)}}</p></td>
                                <td class='right-col' style="padding-top:3px;"> 
                                    <template  v-if="!isMobile()">
                                    <Button class="p-button-raised p-button-rounded green-button__secondary transfer-funds-button" @click="startTransfer(acct)" 
                                    v-tooltip.bottom="'Transfer Playdough to an Investment Account'">
                                    <img src="/assets/images/transfer_funds.png" />
                                    <span class="p-button-label">Transfer Funds</span>
                                    </Button>
                                    <Button class="p-button-raised p-button-rounded green-button__secondary buy-playdough-button" @click="buyMore(acct)" 
                                        v-tooltip.bottom="'Buy more Playdough'" >
                                        <img src="/assets/images/playd-icon.png" />
                                        <span class="p-button-label">Buy More</span>
                                    </Button>
                                    </template>
                                    <template v-else>
                                        <Button class="p-button-raised p-button-rounded green-button__secondary transfer-funds-button" @click="startTransfer(acct)">
                                            <img src="/assets/images/transfer_funds.png" />
                                        </Button>
                                        <Button class="p-button-raised p-button-rounded green-button__secondary buy-playdough-button" @click="buyMore(acct)" >
                                            <img src="/assets/images/playd-icon.png" />
                                        </Button>
                                    </template>
                                </td>
                            </tr>
                            
                        </table>
                    </div>
                    
                </div>
            </div>

             
            <div class="card overview-table">
                <div class="table-header-container">
                    
                    <div class="transactions-wrapper" >
                        <h2 class="table-header">Transactions</h2>
                        <SelectButton class="holdings-view-toggle" :options="txnViewOptions" v-model="txnView"  />
                    </div>
                    
                </div>
               
                
                <TransactionsTable :transactions="transactions" :totalTransactions="totalTransactions" :limitPerPage="limitPerPage" :loadingTransactions="loadingCADTxns || loadingUSDTxns" />
            </div>
        </div>
        <BuyPlayDoughModal @wad-selected="onWadSelected" ref="buyPlayDoughModal"/>
        <BuyPlaydoughViaWebsiteModal ref="buyPlaydoughViaWebsiteModal"/>
        <AccountTransferModal @complete-transfer="onCompleteTransfer" ref="accountTransferModal" :selectedGroup="selectedGroup"/>
    </div>
</template>

<script>

import TransactionsTable from './overview/TransactionsTable.vue';

import PortfolioService from '../../service/PortfolioService';
import AccountTransferModal from './modal/AccountTransferModal.vue';
import BuyPlayDoughModal from './modal/BuyPlayDoughModal.vue';
import BuyPlaydoughViaWebsiteModal from './modal/BuyPlaydoughViaWebsiteModal.vue';

import LoadingSpinner from '../../components/common/LoadingSpinner.vue';
import SelectButton from 'primevue/selectbutton';
import EventBus from '../../event-bus';
import BrowserUtils from '../../utilities/BrowserUtils';
import PaymentService from '../../service/PaymentService';
import GroupService from '../../service/GroupService';

import TimeUtils from '../../utilities/TimeUtils';


export default {
    name: 'PortfolioCashAccounts',
    components: {
    TransactionsTable,
    LoadingSpinner,
    SelectButton,
    AccountTransferModal,
    BuyPlayDoughModal,
    BuyPlaydoughViaWebsiteModal
},
    emits: ['cash-refresh'],

    props: ['selectedGroup'],

    data() {
        return {
            
           
            limitPerPage: 25,
            currentOffset: 0,
            
            errorLoading: false,
            selectedAccount: null,
            txnViewOptions: ["CAD", "USD"],
            txnView: "CAD",
            loadingCADTxns: false,
            loadingUSDTxns: false,
            acctsLoaded: false,
            cashAccounts: [],
            
            
        };
    },

    watch: {
        txnView(val) {
            if( val == "CAD") {
                this.cashAccounts.forEach(acct => {
                    if( acct.currencyId == 1) {
                        
                        this.getTransactionsData(acct, this.limitPerPage, this.currentOffset);
                        this.selectedAccount = acct;
                    }
                });
            }
            else {
                this.cashAccounts.forEach(acct =>{
                    if( acct.currencyId == 2) {
                        this.getTransactionsData(acct, this.limitPerPage, this.currentOffset)
                        this.selectedAccount = acct;
                    }
                });
            }
            
        }
    },

    computed: {
        transactions() {
            return (this.selectedAccount && this.selectedAccount.transactions ? this.selectedAccount.transactions : [] );
        },
        totalTransactions() {
            return (this.selectedAccount && this.selectedAccount.totalTransactions ? this.selectedAccount.totalTransactions : [] );
        },
        
    },

    deactivated() {
        console.log("deactivating cash accounts");
       
    },
    

    created() {
        EventBus.off('refresh-cash-accounts');
        EventBus.on('refresh-cash-accounts', this.loadAccountData);
    },
    mounted() {
        console.log("mounting cash accounts")
         this.loadAccountData();
         let paymentStatus = this.$route.query.payment;
         if(paymentStatus == 'complete') {
            this.$toast.add({ severity: 'success', summary: 'Purchase Complete', detail: 'You Playdough purchase was completed.  It may take a few minutes for your balance to be updated.', life: 5000 ,  group: 'center' });
            this.$emit('cash-refresh');
         } else if (this.$route.path.startsWith('/portfolios/cash') && !this.selectedGroup) {
            this.$emit('cash-refresh');
         }
    },
    activated() {
         console.log("activated cash accounts")
         this.loadAccountData();
         
    },

    methods: {

        startTransfer(acct) {//eslint-disable-line
            console.log("starting transfer for acct:", acct)
            this.$refs.accountTransferModal.open(acct);
        },

        onCompleteTransfer(transfer) {
            if( this.selectedGroup ) {
                transfer["groupId"] = this.selectedGroup.groupId;
                PortfolioService.recordGroupAccountTransfer(transfer).then(resp => {
                    if (resp.data.status === 'success') {
                        this.$toast.add({ severity: 'success', summary: 'The transfer completed successfully.', detail: resp.message, life: 3000 ,  group: 'center' });
                        this.loadAccountData();
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Error transfering funds. Contact us if the issue persists.', detail: resp.message, life: 3000,  group: 'center'  });
                    }
                });
            } else {
                PortfolioService.transferFunds(transfer).then(resp => {
                    if (resp.data.status === 'success') {
                        this.$toast.add({ severity: 'success', summary: 'The transfer completed successfully.', detail: resp.message, life: 3000 ,  group: 'center' });
                        this.loadAccountData();
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Error transfering funds. Contact us if the issue persists.', detail: resp.message, life: 3000,  group: 'center'  });
                    }
                });
            }
        },

        buyMore(acct) {//eslint-disable-line
            if(BrowserUtils.isNativeApp()) {
                this.$refs.buyPlaydoughViaWebsiteModal.open();
            } else {
                this.$refs.buyPlayDoughModal.open(acct);
            }
        },
        
        onWadSelected( wadSelection ) {
            if( this.selectedGroup ) {
                 PaymentService.createGroupPlayDoughCheckoutSession(this.selectedGroup.groupId, wadSelection).then(resp => {
                    if (resp.data.status === 'success') {
                        window.location.href = resp.data.url;
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: resp.message, life: 3000,  group: 'center'  });
                    }
                }).catch(err => {
                    console.log(err);
                    this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: err.message, life: 3000,  group: 'center'  });
                });
            }
            else {
                PaymentService.createPlayDoughCheckoutSession(wadSelection).then(resp => {
                    if (resp.data.status === 'success') {
                        window.location.href = resp.data.url;
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: resp.message, life: 3000,  group: 'center'  });
                    }
                }).catch(err => {
                    console.log(err);
                    this.$toast.add({ severity: 'error', summary: 'Error launching checkout screen. Contact us if the issue persists.', detail: err.message, life: 3000,  group: 'center'  });
                });
            }
        },

      formatMoney(value) {
      
        const formattedValue = '$' + value.toLocaleString('en-US', {
          minimumFractionDigits: 2, 
          maximumFractionDigits: 2
        });
        return formattedValue;
      
    },

       isMobile() {
        return BrowserUtils.isMobile();
       },

        


       


        resetLoadedState(val){
            this.acctsLoaded = val;
             this.loadingTxns = val;
        },

        

        loadAccountData() {
            // console.log('loading overview via methods');
            try {
                // console.log(10);
                this.acctsLoaded = false;
                this.resetLoadedState(false);
                
                if (this.selectedGroup) {
                    GroupService.getGroupCashAccounts(this.selectedGroup.groupId).then(resp => {
                        if (resp.data.status == 'success') {
                            this.cashAccounts = resp.data.cashAccounts;
                            this.cashAccounts.forEach(acct => {
                                this.getTransactionsData(acct, this.limitPerPage, this.currentOffset)
                            })
                            this.selectedAccount = this.cashAccounts.find(acct => acct.currencyId == (this.txnView == 'CAD' ? 1 : 2));
                        }
                        else {
                            console.log("error getting cash accounts " + resp.data.message);
                        }
                        this.acctsLoaded = true;

                    });
                } else {
                    PortfolioService.getCashAccounts().then(resp => {
                        if (resp.data.status == 'success') {
                            this.cashAccounts = resp.data.cashAccounts;
                            this.cashAccounts.forEach(acct => {
                                this.getTransactionsData(acct, this.limitPerPage, this.currentOffset)
                            })
                            this.selectedAccount = this.cashAccounts.find(acct => acct.currencyId == (this.txnView == 'CAD' ? 1 : 2));
                        }
                        else {
                            console.log("error getting cash accounts " + resp.data.message);
                        }
                        this.acctsLoaded = true;

                    });
                }

                
            } catch (err) {
                console.log("loadPCashAccountsData", err);
                this.errorLoading = true;
                //this.$toast.add({ severity: 'error', summary: 'Error loading portfolio data.', detail: 'Contact INVRS support for help by clicking your profile picture.', life: 4000, group: 'center' });
            }
        },

        

        getTransactionsData(acct, limit, offset) {
            if( acct.currencyId == 1) {
                this.loadingCADTxns = true;
            }
            else {
                this.loadingUSDTxns = true;
            }
            
           
            PortfolioService.getTransactions(acct.investmentAccountId, limit, offset).then(transactionsData => {
                if (transactionsData.status === 200) {
                    this.getDataFromTransactions(acct, transactionsData.data);
                }
                if( acct.currencyId == 1) {
                    this.loadingCADTxns = false;
                }
                else {
                    this.loadingUSDTxns = false;
                }
            });
            
            // console.log(transactionsData);

            
        },

        getDataFromTransactions(acct, transactions) {
           
           
            const transactionsArray = transactions.transactions.map((transaction) => {
                const date = new Date(transaction.transactionDate);
              
                const formattedDate = TimeUtils.getFormattedTime(date, "YYYY/MM/DD HH:mm")

                let description = transaction.description;
                

                return {
                    amount: transaction.amount, //< 0 ? transaction.amount * -1 : transaction.amount,
                    currencyCode: transaction.currencyCode,
                    description: description,
                    fee: transaction.fee * -1,
                    quantity: transaction.quantity < 0 ? transaction.quantity * -1 : transaction.quantity,
                    transactionDate: formattedDate,
                    transactionType: transaction.transactionType[0].toUpperCase() + transaction.transactionType.slice(1),
                    transactionSubType: transaction.transactionSubType,
                    exchangeRate: transaction.exchangeRate,
                };
            });

            acct['transactions'] = transactionsArray;
            acct['totalTransactions'] = transactions.total;
          
        },

        loadMoreTransactions(event) {
            this.currentOffset = event.first;
            this.getTransactionsData(this.selectedAccount, this.limitPerPage, this.currentOffset, false);
        },

       
    },
};
</script>

<style scoped>
.overview-table {
    width: 100%;
    margin-bottom: 48px;
}

.grid-header {
    font-family: 'Trebuchet MS';
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    margin-bottom: 24px;
    margin-right: 4px;
    display: flex;
    align-items: center;
}
.grid-header img {
    margin-left: 10px;
    width: 30px;
}
.table-header {
    font-family: 'Trebuchet MS';
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    margin-bottom: 0;
    margin-right: 4px;
}

.table-header-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;
}

.selected-account-card {
    width: 100%;
}
.selected-account-card .p-grid {
    padding: 0px 16px;
}
.selected-account-card table {
    width: 100%;
}
.selected-account-card table .left-col {
    text-align: left;
}
.selected-account-card table .right-col {
    color: black;
    text-align: right;
}

.transfer-funds-button {
    padding: 4px 8px;
    margin-right: 4px;
}
.transfer-funds-button img {
    width: 20px;
    height: 24px;
}
.buy-playdough-button {
    padding: 4px 4px;
}
.buy-playdough-button img {
    width: 30px;
    height: 24px;
}

.holdings-wrapper,
.orders-wrapper,
.transactions-wrapper {
    display: flex;
    align-items: center;
}

::v-deep(.overview-table-toggle .p-highlight),
::v-deep(.overview-table-toggle .p-button:hover),
::v-deep(.overview-table-toggle .p-button:focus) {
    background: #32364e;
    border-color: #32364e;
    color: #ffffff;
    box-shadow: none;
}

::v-deep(.overview-table-toggle.p-selectbutton .p-button.p-highlight) {
    background: #32364e;
    color: #ffffff;
    pointer-events: none;
    z-index: 2;
}

::v-deep(.overview-table-toggle.p-selectbutton .p-button) {
    border-color: #32364e;
}

::v-deep(.overview-table-toggle.p-selectbutton .p-button:first-of-type) {
    border-radius: 2rem 0 0 2rem;
}

::v-deep(.overview-table-toggle.p-selectbutton .p-button:last-of-type) {
    border-radius: 0 2rem 2rem 0;
}

::v-deep(.overview-table-toggle.p-selectbutton .p-button:not(.p-highlight):hover) {
    background: #32364e;
    border-color: #32364e;
    color: #ffffff;
}

::v-deep(.overview-table-toggle .p-selectbutton .p-button-label) {
    padding: 0.1rem 0.5rem;
}

::v-deep(.overview-table-toggle span) {
    font-size: 12px;
}


::v-deep(.holdings-view-toggle .p-highlight),
::v-deep(.holdings-view-toggle .p-button:hover),
::v-deep(.holdings-view-toggle .p-button:focus) {
    background: #32364e;
    border-color: #32364e;
    color: #ffffff;
    box-shadow: none;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button.p-highlight) {
    background: #32364e;
    color: #ffffff;
    pointer-events: none;
    border-radius: 2rem;
    z-index: 2;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button) {
    border-color: #32364e;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button:first-of-type) {
    left: 13px;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button:not(.p-highlight):first-of-type) {
    border-radius: 2rem 0 0 2rem;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button:not(.p-highlight):last-of-type) {
    border-radius: 0 2rem 2rem 0;
}

::v-deep(.holdings-view-toggle.p-selectbutton .p-button:not(.p-highlight):hover) {
    background: #32364e;
    border-color: #32364e;
    color: #ffffff;
}

::v-deep(.holdings-view-toggle .p-selectbutton .p-button-label) {
    padding: 0.1rem 0.5rem;
}

::v-deep(.holdings-view-toggle span) {
    font-size: 12px;
}

::v-deep(*:not(.pi, .material-icons-outlined,.material-symbols-outlined)), ::v-deep(.p-component){
    font-family: 'Trebuchet MS', 'Verdana';
}



@media (max-width: 760px) {
    ::v-deep(.overview-table-toggle) {
        margin-left: auto;
    }
    ::v-deep(.holdings-view-toggle .p-selectbutton > div) {
        padding: 0.1rem 0.2rem;
    }

    ::v-deep(.holdings-view-toggle .p-highlight),
    ::v-deep(.holdings-view-toggle .p-button:hover),
    ::v-deep(.holdings-view-toggle .p-button:focus) ,
    ::v-deep(.holdings-view-toggle .p-button){
        padding: 5px;
    }

    ::v-deep(.holdings-view-toggle.p-selectbutton .p-button) {
        padding-left: 15px;
        padding-right: 15px;
    }

  ::v-deep(.holdings-view-toggle.p-selectbutton .p-button:not(.p-highlight):first-of-type) {
        padding-left: 8px;
    }

    ::v-deep(.holdings-view-toggle.p-selectbutton .p-button:not(.p-highlight):last-of-type) {
        padding-right: 8px;
    }

    ::v-deep(.holdings-view-toggle span) {
        font-size: 10px;
    }



    ::v-deep(.overview-table-toggle .p-selectbutton > div) {
        padding: 0.1rem 0.2rem;
    }

    ::v-deep(.overview-table-toggle .p-highlight),
    ::v-deep(.overview-table-toggle .p-button:hover),
    ::v-deep(.overview-table-toggle .p-button:focus) ,
    ::v-deep(.overview-table-toggle .p-button){
        padding: 5px;
    }

    ::v-deep(.overview-table-toggle.p-selectbutton .p-button) {
        padding-left: 15px;
        padding-right: 15px;
    }

    ::v-deep(.overview-table-toggle.p-selectbutton .p-button:not(.p-highlight):first-of-type) {
            padding-left: 8px;
        }

        ::v-deep(.overview-table-toggle.p-selectbutton .p-button:not(.p-highlight):last-of-type) {
            padding-right: 8px;
        }

        ::v-deep(.overview-table-toggle span) {
            font-size: 10px;
        }
        
       
    
    }

@media (max-width: 592px) {
    .grid-header img {
        width: 25px;
    }
}
     ::v-deep(.toggle-disabled) {
            opacity: 0.3;
            pointer-events: none;
        }    
</style>
