<template >
   
    <PillMenuBar v-if="!noLinkedAccount && portfolioLoaded" :navigationItems="menuItems" headerType="main" class="p-d-md-flex" ref="portfolioMenuBar" v-observe-visibility="visibilityChanged">
        <template #custom>
            <div class='account-dropdown-container'>
                <span class='account-icon' v-html="iconAccountProcessor(selectedAccountName)"></span>
                <Dropdown v-if="visibleAccounts" class="account-selector" v-model="selectedAccountName" :options="visibleAccountsList" panelClass='account-selector-dropdown-panel'>
                    <template #option="slotProps">
                        <div class='account-dropdown-item'>
                            <span class='account-icon' v-html="iconAccountProcessor(slotProps.option)"></span>
                            <span v-if="slotProps.option.indexOf('failed')>=0" class="failed-import-item">{{slotProps.option}}</span>
                            <span v-else-if="slotProps.option.indexOf('waiting')>=0 || slotProps.option.indexOf('Importing')>=0" class="waiting-import-item">{{iconAccountProcessor(slotProps.option)}} {{slotProps.option}}</span>
                            <span v-else-if="slotProps.option.indexOf('Disconnected')>=0" class="failed-import-item">{{iconAccountProcessor(slotProps.option)}} {{slotProps.option}}</span>
                            <span v-else-if="slotProps.option.indexOf('SUSPENSION')>=0" class='suspended'>{{iconAccountProcessor(slotProps.option)}} {{slotProps.option}}</span>
                            <span v-else class='dropdown-option'>{{slotProps.option}}</span>
                        </div>
                    </template>
                </Dropdown>
            </div>
            <template v-if="!isMobile()">
            <Button v-if="visibleAccounts && isFailedImport()" @click="retryImport" :class="addAccountButtonClass" class="retry-import-button">
                <span>Retry Import</span>
            </Button>
            <Button v-if="visibleAccounts && isAccountDisconnected()" @click="reconnectAccount" :class="addAccountButtonClass" class="retry-import-button">
                <span>Reconnect Account</span>
            </Button>
            <Button :class="{...addAccountButtonClass, 'add-account-button': true, 'green-button__secondary': true}" class="add-account-button" @click="addAccountHandler" :disabled="isAccountStillInImport(selectedAccountData)">
                <span>Add account</span>
            </Button>
            </template>
            <template v-else>
                <Button @click="addAccountHandler" :class="{...addAccountButtonClass, 'blue-button': false}" icon="pi pi-plus" v-tooltip.left="'Add Account'" />
                 
            </template>
            <template v-if="visibleAccounts && visibleAccountsList.length > 0 && !this.isCommunityPortfolio && !this.isAggregatedPortfolio">
                <Button @click="toggleSettingsMenu" :class="{...addAccountButtonClass, 'blue-button': true, 'settings-button': true}" v-tooltip.left="'Share your portfolio with the community or disconnect your account'" >
                    <img src="assets/images/settings.png" />
                </Button>
                <Menu ref="settingsMenu" :model="settingsMenuItems" :popup="true" />
            </template>
        </template>
    </PillMenuBar>
   
    <div class="tab-div" id='portfolioOverviewContainer' v-if="!noLinkedAccount && portfolioLoaded">
        <Overview :selectedAccountData="selectedAccountData" :fullLoad="fullLoad" v-if="selectedAccountData && $route.path == '/portfolios'" @reload-account="reloadAccount" />
        <CashFlow :selectedAccountData="selectedAccountData" :fullLoad="fullLoad" v-else-if="selectedAccountData && $route.path  == '/portfolios/cash-flow'" />
        <div class="card overview-table"  v-else-if="$route.path  == '/portfolios/performers'">
            <div class="performers-header-container">
                <div class="performers-wrapper" >
                    <h2 class="table-header">Top Performers</h2>
                    <i class="pi pi-info-circle" v-tooltip.right="'Based on average daily returns over past month'"></i>
                </div>
            </div>
            <TopPerformersTable />
        </div>
        <CashAccounts v-else-if="$route.path  == '/portfolios/cash'" @cash-refresh="onCashRefresh"/>
    </div>
   
    <AddAccountOptionsModal ref='addAccountOptionsModal' @create-paper-account="onCreatePaperAccountCallback" @open-upgrade-to-premium-modal="openUpgradeToPremiumModal"/>
    <AddPaperAccountModal ref='addPaperAccountModal' @created-account="createdAccountCallback" />
    <ConfirmModal headerText='Disconnect account?' confirmButtonLabel='Disconnect' @confirmed="hideAccount" ref='confirmDisconnectModal'/>
    <ConfirmModal headerText='Delete account?' confirmButtonLabel='Delete' @confirmed="deletePaperAccount" ref='confirmDeleteModal'/>
    <Dialog v-if="selectedAccountData" class='import-progress-overlay' :visible="isAccountStillInImport(selectedAccountData)" :modal="true" :draggable='false' 
        :closable="false" appendTo="#portfolioOverviewContainer" @show="onShown">
        {{selectedAccountData.lastSyncStatus}}...
        <span class='import-error-message' v-if="selectedAccountData.lastSyncStatus.indexOf('failed') >= 0">{{errorMessage}}</span>
        <LoadingSpinner :showLoading="true" minHeight="100px" v-else/>
    </Dialog>
    <Dialog class='edit-account-settings-modal' :modal="true" :draggable='false' :header="editAccountSettingsModalObject['header']" 
    v-model:visible="displayEditAccountSettingsModal" v-if='selectedAccountData'>
        {{editAccountSettingsModalObject['content']}}
        <template #footer>
            <Button class='blue-button' label='Cancel' @click='closeEditAccountSettingsModal()'/>
            <Button :class="{'green-button__primary': selectedAccountData.sharingLevel === 1, 'red-button__secondary': selectedAccountData.sharingLevel === 5}" 
            :label="selectedAccountData.sharingLevel === 1 ? 'Share' : 'Make Private'" @click="toggleAccountPrivacy" />
        </template>
    </Dialog>
    <UpgradeToPremiumModal ref="upgradeToPremiumModal" :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText="upgradeToPremiumModalText"/>
</template>

<script>
import PillMenuBar from '../components/menu/PillMenuBar';
import Overview from '../components/portfolio/Overview.vue';
import CashFlow from '../components/portfolio/CashFlow.vue';
import TopPerformersTable from '../components/portfolio/overview/TopPerformersTable.vue';
import AddAccountOptionsModal from '../components/portfolio/modal/AddAccountOptionsModal.vue';
import UpgradeToPremiumModal from '../components/modal/UpgradeToPremiumModal.vue';
import AddPaperAccountModal from '../components/portfolio/modal/AddPaperAccountModal.vue';
import Dropdown from 'primevue/dropdown';
import ConfirmModal from '../components/modal/ConfirmModal.vue';
import Dialog from 'primevue/dialog';
import LoadingSpinner from '../components/common/LoadingSpinner.vue';
import Menu from 'primevue/menu';
import CashAccounts from '../components/portfolio/CashAccounts.vue';

import {PAPER_ANALYSIS_ACCOUNT_TYPE, PAPER_TRADING_ACCOUNT_TYPE, COMPETITION_ACCOUNT_TYPE, PREMIUM_SUBSCRIPTION_TYPES, EDUCATIONAL_ACCOUNT_TYPE} from '../common/constants';
import PortfolioService from '../service/PortfolioService';
import BrowserUtils from '../utilities/BrowserUtils';
import CurrencyUtils from '../utilities/CurrencyUtils';

import EventBus from '../event-bus';

export default {
    name: 'Portfolios',
    components: {
    PillMenuBar,
    Dropdown,
    Overview,
    CashFlow,
    ConfirmModal,
    Dialog,
    LoadingSpinner,
    Menu,
    TopPerformersTable,
    AddAccountOptionsModal,
    AddPaperAccountModal,
    CashAccounts,
    UpgradeToPremiumModal
},

    data() {
        return {
            PREMIUM_SUBSCRIPTION_TYPES,

            noLinkedAccount: false,
            portfolioLoaded: false,
            selectedAccountName: null,
            accountNamesList: [],
            selectedAccountData: null,
            accountDataList: [],
            hiddenAccountsList: [],
            visibleAccountsList: [],
            plaidHandler: null,
            loadingPortfolioFromStore: false,
            fullLoad: false,
            errorMessage:  null,
            displayEditAccountSettingsModal: false,
            importInProgress: false,
            isCreateManualAccountClicked: false,
        };
    },

    computed: {
         menuItems(){
            if( !BrowserUtils.isMobile()) {
                return [
                    { label: 'Overview', to: '/portfolios' }, 
                    { label: 'Cash Flow', to: '/portfolios/cash-flow', disabled: !this.cashflowSettingsEnabled(), className: !this.isCommunityPortfolio ? 'purple-button__secondary' : null},
                    // { label: 'Cash Accounts', to: '/portfolios/cash'},
                    { label: 'Top Performers', to: '/portfolios/performers', disabled: !this.isCommunityPortfolio  }, 
                ];
            }
            else {
                return [];
            }
         },
        addAccountButtonClass() {
            return {
                'p-button-raised': true,
                'p-button-rounded': true,
                active: false,
            };
        },

        upgradeToPremiumModalText() {
            return "To create a manual account and get access to all the other premium tools, upgrade to premium and lock in the current low price for as long as you keep your subscription active.";
        },

        visibleAccounts() {
            return this.visibleAccountsList.length > 0;
        },

        recentlyViewedPortfolio() {
            return this.$store.getters['recentPortfolio'];
        },

        portfolioHoldingsData() {
            return this.$store.getters['portfolioHoldingsData'];
        },

        portfolioAllocationData() {
            return this.$store.getters['portfolioAllocationData'];
        },

        portfolioPerformanceData() {
            return this.$store.getters['portfolioPerformanceData'];
        },

        comparisonPerformanceData() {
            return this.$store.getters['comparisonPerformanceData'];
        },

        accountList() {
            return this.$store.getters['accountList'];
        },

        hasRecentlyViewedPortfolio() {
            return this.accountList && this.comparisonPerformanceData && this.portfolioPerformanceData && this.portfolioHoldingsData && this.recentlyViewedPortfolio
                && this.recentlyViewedPortfolio.portfolioName && !this.recentlyViewedPortfolio.portfolioData.groupPortfolioId;
        },

        hasPortfoliosEnabled() {
            return this.$store.state.users?.user?.features.includes("Portfolios");
        },
        
        settingsMenuItems() {
            return this.getSettingsMenuItems();
        },

        editAccountSettingsModalObject() {

            let obj = {
                'header': '',
                'content': ''
            };

            if (this.selectedAccountData.sharingLevel === 1) {
                obj['header'] = 'Share Portfolio';
                obj['content'] = `Share your portfolio with the INVRS community (percentages only, dollar amounts are never shown). 
                We never store user credentials and all account connections are encrypted.`;
            } else {
                obj['header'] = 'Make Portfolio Private';
                obj['content'] = `Hide your portfolio from the INVRS community. You will be the only one that can see your 
                portfolio data including holdings, peformance, allocation percentages, etc.`;
            }

            return obj;
        },
        isCommunityPortfolio() {
            if( !this.selectedAccountData ) {
                return true;
            }
            else {
                return this.selectedAccountData.investmentAccountId == -999;
            }
        },
        isAggregatedPortfolio() {
            if( !this.selectedAccountData ) {
                return true;
            }
            else {
                return this.selectedAccountData.investmentAccountId == -998;
            }
        },
        isCashAccounts() {
             if( !this.selectedAccountData ) {
                return true;
            }
            else {
                return this.selectedAccountData.investmentAccountId == -997;
            }
        }
    },

    watch: {
        $route(newRoute, oldRoute) { //eslint-disable-line
            // console.log("portfolios route", oldRoute, newRoute, this.selectedAccountName);
            if (this.selectedAccountName == 'Cash Accounts (CAD/USD)' 
                    && oldRoute.path.startsWith('/portfolios/cash') 
                    && newRoute.fullPath == '/portfolios') {

                this.selectedAccountName = this.visibleAccountsList[0];

            }
        },

       selectedAccountData: {
            handler(value, oldValue) {
                if (!value) return;

                if (this.loadingPortfolioFromStore) {
                    this.loadingPortfolioFromStore = false;
                    return;
                }

                if( value.investmentAccountId == -997) {
                    // cash accounts
                    this.$router.push('/portfolios/cash');
                    if( !this.isMobile()) {
                        this.menuItems[0].disabled = true;
                        this.menuItems[1].disabled = true;
                    }
                }
            

                else if( oldValue && oldValue.investmentAccountId == -997) {
                    this.$router.push('/portfolios');
                    
                }
                
                if( !this.isCommunityPortfolio && !this.isMobile()) {
                    this.menuItems[2].disabled = true;
                   
                    if( this.$route.path  == '/portfolios/performers') {
                        this.$router.push('/portfolios');
                    }
                }
                // this.$store.dispatch({
                //     type: 'setRecentPortfolio',
                //     payload: {
                //         portfolioData: value,
                //         portfolioName: this.selectedAccountName,
                //     },
                // });
                if( !BrowserUtils.isMobile() ) {
                    if (this.selectedAccountData.lastSyncStatus.includes("Importing")) {
                        this.menuItems[1].disabled = true;
                        return;
                    }

                    if (this.selectedAccountData.lastSyncStatus === "Import Complete") {
                        this.menuItems[1].disabled = !this.cashflowSettingsEnabled() || this.isCashAccounts;
                        return;
                    }
                }

                if(this.isAccountDisconnected() ){
                    this.warnAboutDisconnectedAccount();
                }

            },
            deep: true
        },

        selectedAccountName(value, oldValue) {
            if(!value) {
                this.selectedAccountData = null;
                return;
            }

            if (oldValue) {
                this.fullLoad = true;
                
            } else {
                this.fullLoad = false;
               
            }
            //console.log("select account name changing from "+ oldValue+" to "+value, this.accountDataList);

            const newAccountIndex = this.accountDataList.findIndex((account) => {
                return account.fullName === value || account.name === value;
            });
            //console.log("index for value is "+ newAccountIndex);
            this.selectedAccountData = this.accountDataList[newAccountIndex];

            if( this.selectedAccountData?.investmentAccountId > 0 ) {
                PortfolioService.getInvestmentAccount(this.selectedAccountData.investmentAccountId).then(resp => {
                    if( resp.data.status == 'success') {
                        this.selectedAccountData.totalValue = resp.data.account.totalValue;
                        this.selectedAccountData.cashPositions = resp.data.account.cashPositions;
                        this.selectedAccountData.totalBorrowed = resp.data.account.totalBorrowed;
                        this.selectedAccountData.currentBuyingPower = resp.data.account.currentBuyingPower;
                        this.selectedAccountData.contest = resp.data.account.contest;
                    }
                    this.$store.commit('SET_CURRENTLY_SELECTED_PORTFOLIO', this.selectedAccountData);
                });
            }
            else {

                this.$store.commit('SET_CURRENTLY_SELECTED_PORTFOLIO', this.selectedAccountData);
            }
            
        },

         

        
    },

    activated() {
        EventBus.off('portfolio-imported-reload');
        EventBus.off('portfolio-import-failed');
        EventBus.off('portfolio-import-status');
        EventBus.off('reload-account');
        EventBus.off('get-accounts');
        EventBus.on('select-account', this.selectAccount);
        EventBus.on('portfolio-imported-reload', this.importCompleteHandler);
        EventBus.on('portfolio-import-failed', this.importFailedHandler);
        EventBus.on('portfolio-import-update', this.importUpdateHandler);
        EventBus.on('portfolio-update', this.portfolioUpdateHandler);
        EventBus.on('get-accounts', this.getAccountsHandler);
        EventBus.on('loaded-overview', () => {this.fullLoad = false})
        EventBus.on('reload-account', this.reloadAccount);
        EventBus.emit('track-portfolio-visit');

        if (this.hasPortfoliosEnabled) {
            this.initializePortfolio();
        }

        const importStatus = this.$route.query.importStatus;
        const reconnect = this.$route.query.reconnect;
        if (importStatus === 'success') {
            this.onConnectingAccount();
        }
        else if( reconnect === 'success') {
            this.onReconnectingAccount();
            
        }
        else if(this.isAccountDisconnected() ){
           this.warnAboutDisconnectedAccount();
        }


    },

    deactivated() {
        EventBus.off('portfolio-imported-reload');
        EventBus.off('portfolio-import-failed');
        EventBus.off('portfolio-import-update');
        EventBus.off('portfolio-update');
        EventBus.off('get-accounts');
        EventBus.off('select-account');
        this.noLinkedAccount= false;
        this.portfolioLoaded= false;
        this.selectedAccountName = null;
        this.accountNamesList= [];
        this.selectedAccountData= null;
        this.accountDataList= [];
        this.hiddenAccountsList= [];
        this.visibleAccountsList= [];
        this.plaidHandler= null;
        this.loadingPortfolioFromStore= false;
        this.fullLoad= false;
    },

    methods: {
        visibilityChanged (isVisible) {
            if(isVisible) {
                setTimeout(() => EventBus.emit("portfolio-opened"), 200);
            }
        },

        openUpgradeToPremiumModal(isCreateManualAccountClicked) {
            this.isCreateManualAccountClicked = isCreateManualAccountClicked;
            this.$refs.upgradeToPremiumModal.open();
        },

        selectAccount(account) {
            this.selectedAccountName = this.buildAcctName(account);
        },
        reloadAccount() {
            this.fullLoad = true;
            if (!this.isAggregatedPortfolio && !this.isCommunityPortfolio) {
                PortfolioService.getInvestmentAccount(this.selectedAccountData.investmentAccountId).then(resp => {
                    if( resp.data.status == 'success') {
                        this.selectedAccountData.totalValue = resp.data.account.totalValue;
                        this.selectedAccountData.cashPositions = resp.data.account.cashPositions;
                        this.selectedAccountData.totalBorrowed = resp.data.account.totalBorrowed;
                        this.selectedAccountData.currentBuyingPower = resp.data.account.currentBuyingPower;
                    }
                });
            } else {
                EventBus.emit('refresh-portfolio-overview');
            }
        },
        onCashRefresh() {
             const cashAccountIndex = this.accountDataList.findIndex((account) => {
                return account.investmentAccountId == -997
            });
            //console.log("index for value is "+ newAccountIndex);
            let acctToSelect = this.accountDataList[cashAccountIndex];
            
            this.selectedAccountName = acctToSelect.fullName;
        },

        iconAccountProcessor(name) {
            const account = this.accountDataList.filter(acct => name.indexOf(acct.name) >= 0)[0];

            if (account.investmentAccountId === -997) {
                return "<img class='cash-account-icon' src='/assets/images/cash-account.png' />"
            } else if (account.investmentAccountId === -998) {
                return "<img class='your-portfolio-icon' src='/assets/images/your-port.png' />"
            } else if (account.investmentAccountId === -999) {
                return "<img class='community-icon' src='/assets/images/community_icon.png' />"
            } else if ([PAPER_ANALYSIS_ACCOUNT_TYPE['ID'], PAPER_TRADING_ACCOUNT_TYPE['ID']].includes(account.accountTypeId)) {
                return "<img class='manual-account-icon' src='/assets/images/manual-account-icon.png' />"
            } else if (account.accountTypeId === COMPETITION_ACCOUNT_TYPE['ID']) {
                return "<img class='competition-account-icon' src='/assets/images/competition-account-icon.png' />"
             } else if (account.accountTypeId === EDUCATIONAL_ACCOUNT_TYPE['ID']) {
                return "<img class='competition-account-icon' src='/assets/images/eduAccount.png' />"
            } else {
                return "<img class='connected-account-icon' src='/assets/images/connected-account-icon.png' />"
            }
        },

        warnAboutDisconnectedAccount() {
             this.$toast.add({ severity: 'warn', summary: "This account has been disconnected by your brokerage.", 
                detail:"Please click the 'Reconnect Account' button in order to ensure your holdings and performance metrics are up to date.", life: 7000, group: 'center' });
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },
        cashflowSettingsEnabled() {
            return PortfolioService.cashflowSettingsEnabled();
        },

        cashflowFeatureEnabled() {
            return PortfolioService.cashflowFeatureEnabled();
        },

        onShown() {
            const dialogElement = document.getElementsByClassName('import-progress-overlay')[0];
            dialogElement.parentElement.style.position = 'absolute';
            dialogElement.parentElement.style.height = '100vh';
            dialogElement.parentElement.style.top = '-5px';
            dialogElement.parentElement.style.left = '-30px';
            dialogElement.parentElement.style.width = "calc(100% + 55px)";
            dialogElement.parentElement.style.background = "rgba(255,255,255,0.8)";
        },

        isFailedImport() {
            return this.selectedAccountData != null && this.selectedAccountData.lastSyncStatus.indexOf('failed')>=0;
        },

        isAccountDisconnected() {
            return this.selectedAccountData != null && this.selectedAccountData.lastSyncStatus == 'Account Disconnected';
        },

        retryImport() {
            PortfolioService.retryImport(this.selectedAccountData.institutionId, this.selectedAccountData.extAccountId).then(resp=> {
                if( resp.data.status == 'success') {
                     this.$toast.add({ severity: 'success', summary: "We are retrying the account import. This can take a few minutes.", life: 7000, group: 'center' });
                }
                else {
                    this.$toast.add({ severity: 'error', summary: "We are currently unable to retry the account import.", life: 7000, group: 'center' });
                }
            });
        },

        async reconnectAccount() {
            const redirectUrl = await this.getReconnectionParams();
            if (redirectUrl === 'error') {
                return;
            } 
            window.location.href = redirectUrl;
        },

        async deleteAllAccounts() {
            await PortfolioService.deleteAccount();
        },

        initializePortfolio() {

            // console.log("initializePortfolio",  this.hasRecentlyViewedPortfolio);
           // if (!this.hasRecentlyViewedPortfolio) {
                this.getListOfAccounts(true);
            // } else {
            //     this.loadingPortfolioFromStore = true;

            //     const { accountNames, accountData, visibleAccounts, hiddenAccounts } = this.accountList;
            //     this.accountNamesList = accountNames;
            //     this.accountDataList = accountData;
            //     this.visibleAccountsList = visibleAccounts;
            //     this.hiddenAccounts = hiddenAccounts;

            //     const { portfolioName } = this.recentlyViewedPortfolio;
            //     this.selectedAccountName = portfolioName;
            //     this.portfolioLoaded = true;
            // }
        },

        async getConnectionParams() {
            const params = await PortfolioService.getConnectionParams();
            if (params.data.status === "error") {
                this.$toast.add({ severity: 'error', summary: "We are unable to connect to portfolios currently. If the problem persists please contact support.", life: 5000, group: 'center' });
                return 'error';
            }
            return params.data.params.url;
        },

        async getReconnectionParams() {
            const params = await PortfolioService.getReconnectionParams(this.selectedAccountData.institutionId);
            if (params.data.status === "error") {
                this.$toast.add({ severity: 'error', summary: "We are unable to reconnect to portfolios currently. If the problem persists please contact support.", life: 5000, group: 'center' });
                return 'error';
            }
            return params.data.params.url;
        },

        onCreatePaperAccountCallback(accountTypeId) {
            this.$refs.addPaperAccountModal.open(accountTypeId);
        },

        createdAccountCallback(data) {
            data.account['fullName'] = this.buildAcctName(data.account);
           
            this.accountNamesList.push(data.account.fullName);
            this.accountDataList.push(data.account);
            this.visibleAccountsList.push(data.account.fullName);
                
            this.selectedAccountName = data.account.fullName;

            if(this.$route.fullPath != '/portfolios'){
                this.$router.push('/portfolios');
            }
        },

        async addAccountHandler() {
            this.$refs.addAccountOptionsModal.open();
        },

        onConnectingAccount() {
            this.$toast.add({ severity: 'success', summary: "If you connected an account we are importing it now. This can take a few minutes.", life: 7000, group: 'center' });
        },

        onReconnectingAccount() {
            this.$toast.add({ severity: 'success', summary: "If your account was reconnected we will update your holdings and returns shortly.  This can take a few minutes.", life: 7000, group: 'center' });
        },

        importCompleteHandler(importJob) {
            const {accountNumber} = importJob;

            this.$store.commit('CLEAR_PORTFOLIO_STATE');
            // this.$store.dispatch({
            //     type: 'clearPortfolioState',
            // });

            if( this.noLinkedAccount ) {
                
                this.portfolioLoaded = true;
                this.noLinkedAccount = false;
            }

            /* If we're adding an account for the first time or currently selected an account that's being imported - We automatically select the account.
            If none of the above, we just retrieve the updated list of returned accounts but don't do any of the selecting */
            if (!this.selectedAccountData || this.selectedAccountData.partialAccountNum === accountNumber) {
                this.getListOfAccounts(true);
            } else {
                this.getListOfAccounts(false);
            }
            //window.location.reload();

            
        },

        importFailedHandler(importJob) {
            //console.log('import failed handler')
            const {accountName, accountNumber, institutionName, status, extAccountId} = importJob;

            // If we're not on the account page that's being imported
            if (this.selectedAccountData && this.selectedAccountData.extAccountId !== extAccountId) {
                this.$toast.add({ severity: 'error', summary: `Error connecting ${institutionName} account "${accountName}(${accountNumber})".`, detail: 'Error['+status+']: Please try again or contact INVRS support for help by clicking your profile picture.',  group: 'center' });
            } else {
                this.errorMessage = null;
                this.errorMessage = `Error connecting ${institutionName} account "${accountName}(${accountNumber})".\nError[${status}]: Please try again or contact INVRS support for help by clicking your profile picture.`;
            }
            
            if( this.noLinkedAccount ) {
                
                this.portfolioLoaded = true;
                this.noLinkedAccount = false;
            }
            this.getListOfAccounts(true);
        },

        portfolioUpdateHandler(investmentAccount){
            if (!this.selectedAccountData || this.selectedAccountData.investmentAccountId === investmentAccount.investmentAccountId) {
                this.getListOfAccounts(true);
            } else {
                this.getListOfAccounts(false);
            }
        },

        importUpdateHandler(importJob) {
            // console.log(importJob)
           
            const {extAccountId} = importJob;

            // console.log("import update handler called with status " + status);

            if( this.noLinkedAccount ) {
                
                this.portfolioLoaded = true;
                this.noLinkedAccount = false;
            }
            
            /* If we're adding an account for the first time or currently selected an account that's being imported - We automatically select the account.
            If none of the above, we just retrieve the updated list of returned accounts but don't do any of the selecting */
            if (!this.selectedAccountData || this.selectedAccountData.extAccountId === extAccountId) {
                this.getListOfAccounts(true);
            } else {
                this.getListOfAccounts(false);
            }
        },

        getListOfAccounts(fullLoad = false) {
            //console.log("getting list of accounts")
            PortfolioService.getListOfAccounts().then(accountsList =>{
                if (accountsList.status === 200) {
                    this.getDataFromListOfAccounts(accountsList.data, fullLoad);
                }
            });

            
        },

        getDataFromListOfAccounts(accountsList, fullLoad) {
            //console.log("getDataFromListOfAccounts "+ accountsList);
            this.accountNamesList = [];
            this.accountDataList = [];
            this.hiddenAccountsList = [];
            this.visibleAccountsList = [];

            if (accountsList.status === 'error') {
                this.$toast.add({ severity: 'error', summary: 'Error getting accounts.', life: 1500, group: 'center' });
                this.$store.commit('SET_ACCOUNT_LIST',  {
                        accountNames: this.accountNamesList,
                        accountData: this.accountDataList,
                        visibleAccounts: this.visibleAccountsList,

                    });

                // this.$store.dispatch({
                //     type: 'setAccountList',
                //     payload: {
                //         accountNames: this.accountNamesList,
                //         accountData: this.accountDataList,
                //         visibleAccounts: this.visibleAccountsList,

                //     },
                // });
                return;
            }

            if (accountsList.total === 0) {
                this.portfolioLoaded = true;
                this.noLinkedAccount = true;
                return;
            }

            accountsList.accounts.forEach((acct) => {
                // console.log("Adding to accountDataList");
                acct['fullName'] = this.buildAcctName(acct);
                const accountName = acct.fullName; //acct.name;
                this.accountNamesList.push(accountName);
                this.accountDataList.push(acct);

                if (acct.hidden === true) {
                    this.hiddenAccountsList.push(accountName);
                } else {
                    this.visibleAccountsList.push(accountName);
                }
            });
            //console.log("setting account list ");
             this.$store.commit('SET_ACCOUNT_LIST',  {
                        accountNames: this.accountNamesList,
                    accountData: this.accountDataList,
                    visibleAccounts: this.visibleAccountsList,
                    hiddenAccounts: this.hiddenAccountsList,

                    });
            // this.$store.dispatch({
            //     type: 'setAccountList',
            //     payload: {
            //         accountNames: this.accountNamesList,
            //         accountData: this.accountDataList,
            //         visibleAccounts: this.visibleAccountsList,
            //         hiddenAccounts: this.hiddenAccountsList,
            //     },
            // });

            if (fullLoad) {
                let account = null;

                /* If we're currently selecting an account that's still being imported, we find that same account that we retrieved
                from our returned list and replace it with the new one since that is the one with the new import status */

                if (this.selectedAccountName) {
                    this.accountDataList.forEach((acc) => {
                        if (this.isAccountStillInImport(acc) && acc.partialAccountNum === this.selectedAccountData.partialAccountNum) {
                            account = acc;
                            return;
                        }
                    })

                    if (account) {
                        this.selectedAccountName = account.fullName || account.name;
                    } else {
                        this.selectedAccountName = this.visibleAccountsList[0];
                    }
                } else { 
                    /*
                    SCENARIO 1: If we're starting with no accounts at all, we just auotmatically select the account you're trying to add 
                    SCENARIO 2: You have selected an account, exited out of Portfolios, came back and select the last account you visited 
                    SCENARIO 3: You're entering Portfolios for the first time, we just automatically select the first account from your account list */
                    if (!this.recentlyViewedPortfolio) {
                        this.selectedAccountName = this.visibleAccountsList[0];
                    } else {
                        this.selectedAccountName = this.recentlyViewedPortfolio.portfolioName;
                    }
                }


                EventBus.emit('track-linked-accounts', this.accountDataList.length);
            }

            this.portfolioLoaded = true;
        },

        
        buildAcctName(acct){
            if( acct.accountTypeId == PAPER_ANALYSIS_ACCOUNT_TYPE['ID'] ) {
                return acct.name+`(Manual ${CurrencyUtils.getCurrencySymbolViaId(acct.currencyId)})`;
            }
            else if( acct.accountTypeId == COMPETITION_ACCOUNT_TYPE['ID'] ) {
                return acct.name+` (${CurrencyUtils.getCurrencySymbolViaId(acct.currencyId)})`;
            }
            else if( acct.accountTypeId == EDUCATIONAL_ACCOUNT_TYPE['ID'] ) {
                return acct.name+` (${CurrencyUtils.getCurrencySymbolViaId(acct.currencyId)})`;
            }
            else if( acct.accountTypeId == PAPER_TRADING_ACCOUNT_TYPE['ID'] ) {
                return acct.name+`(Paper Trading ${CurrencyUtils.getCurrencySymbolViaId(acct.currencyId)})`;
            }
            else {
                let suffix = ""

                if( acct.lastSyncStatus.indexOf("failed")>0 || this.isAccountStillInImport(acct) || acct.lastSyncStatus.indexOf("Disconnected") > 0){
                    suffix = `[${acct.lastSyncStatus}]`;
                } else if (acct?.suspensionDate) {
                    suffix = '[SUSPENDED]';
                }
                if( !acct.partialAccountNum || acct.partialAccountNum == ''){
                    return acct.name+" ("+acct.extAccountId.substring(0,8)+")"+suffix;
                }
                else {
                    try {
                        if( acct.partialAccountNum.length > 9) {
                            let lastDash = acct.partialAccountNum.lastIndexOf("-");
                            if( lastDash >= 0 ) {
                                return acct.name+" ("+acct.partialAccountNum.substring(lastDash+1)+")"+suffix;
                            }
                            else {
                                return acct.name+" ("+acct.partialAccountNum.substring(acct.partialAccountNum.length-10)+")"+suffix;
                            }
                        }
                        else {
                            return acct.name+" ("+acct.partialAccountNum+")"+suffix;
                        }
                    } catch(error) {
                        console.error("error building name returning default:"+error);
                        return acct.name+" ("+acct.partialAccountNum+")"+suffix; 
                    }
                }
            }
        },

        showDeleteAccountModal() {
            this.$refs.confirmDisconnectModal.open();
        },

        hideDeleteAccountModal() {
            this.$refs.confirmDisconnectModal.closeAndCallback();
        },

        async deletePaperAccount() {
            const status = await PortfolioService.deletePaperAccount(this.selectedAccountData.investmentAccountId);
            if (status.data.status === 'success') {
                this.$toast.add({ severity: 'success', summary: 'Account successfully deleted.', life: 1500, group: 'center' });
                const visibleAccountsCopy = [...this.visibleAccountsList];
                const accountIndex = this.visibleAccountsList.indexOf(this.selectedAccountName);
                visibleAccountsCopy.splice(accountIndex, 1);


                this.getListOfAccounts(true);
            } else {
                this.$toast.add({ severity: 'error', summary: 'There was a problem deleting your account. Please try again.', life: 1500, group: 'center' });
            }
        },

        async hideAccount() {
            //console.log('disconnect account');
            const status = await PortfolioService.hideAccount(this.selectedAccountData.investmentAccountId);
            if (status.data.status === 'success') {
                this.$toast.add({ severity: 'success', summary: 'Account successfully disconnected.', life: 1500, group: 'center' });
                const visibleAccountsCopy = [...this.visibleAccountsList];
                const accountIndex = this.visibleAccountsList.indexOf(this.selectedAccountName);
                visibleAccountsCopy.splice(accountIndex, 1);

                // if user has hidden all of their accounts then remove the entire connection
                if (visibleAccountsCopy.length === 0) {
                    this.deleteAllAccounts();
                    this.$store.commit('CLEAR_PORTFOLIO_STATE');
                    // this.$store.dispatch({
                    //     type: 'clearPortfolioState'
                    // })
                    this.portfolioLoaded = true;
                    this.noLinkedAccount = true;
                    return;
                }

                this.getListOfAccounts(true);
            } else {
                this.$toast.add({ severity: 'error', summary: 'There was a problem deleting your account. Please try again.', life: 1500, group: 'center' });
            }
        },

        isAccountStillInImport(account) {
            if (account) {
                const status = account.lastSyncStatus;

                return status.indexOf('waiting') >= 0 || status.indexOf('Importing') >= 0;
            }

            return false;
        },

        toggleSettingsMenu(event) {
            this.$refs.settingsMenu.toggle(event);
        },

        closeEditAccountSettingsModal() {
            this.displayEditAccountSettingsModal = false;
        },

        getSettingsMenuItems() {
            let menuItems = [];

            const open = () => {
                this.displayEditAccountSettingsModal = true;
            }

            const disable = this.isAccountStillInImport(this.selectedAccountData) || this.selectedAccountData.lastSyncStatus.indexOf('failed') >= 0 || this.selectedAccountData.investmentAccountId < 0 ;
            
            if (this.selectedAccountData.accountTypeId !== 72  ){
                if (this.selectedAccountData.sharingLevel === 1 ) {
                    menuItems.push({
                        label: 'Share Account',
                        command: open,
                        disabled: disable
                    });
                } else {

                    const label = 'Make Account Private'; 
            
                    menuItems.push({
                        label: label,
                        command: open,
                        disabled: disable
                    });
                }
            }
            if ( this.selectedAccountData.accountTypeId >= 73 ) {
                menuItems.push({
                    label: 'Delete Account',
                    command: () => {
                        this.$refs.confirmDeleteModal.open();
                    },
                    disabled: disable
                });
             }

            return menuItems;
        },

        async toggleAccountPrivacy() {
            if (this.selectedAccountData.sharingLevel === 1) { // If account is currently private/hidden
                PortfolioService.shareAccount(this.selectedAccountData.investmentAccountId, 5).then((resp) => {
                    if (resp.data.status === 'success') {
                        this.$toast.add({ severity: 'success', summary: 'Account successfully shared!', life: 1500, group: 'center' });
                        this.selectedAccountData.sharingLevel = 5;
                        EventBus.emit('track-sharing-portfolio-on-personal-profile');
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'There was a problem sharing your account. Please try again.', life: 1500, group: 'center' });
                    }

                    this.displayEditAccountSettingsModal = false;
                })
            } else { //If account is currently public/shared
                PortfolioService.shareAccount(this.selectedAccountData.investmentAccountId, 1).then((resp) => {
                    if (resp.data.status === 'success') {
                        this.$toast.add({ severity: 'success', summary: 'Account is now private!', life: 1500, group: 'center' });
                        this.selectedAccountData.sharingLevel = 1;
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'There was a problem hiding your account. Please try again.', life: 1500, group: 'center' });
                    }

                    this.displayEditAccountSettingsModal = false;
                })
            }
        },

        getAccountsHandler() {
            this.getListOfAccounts(true);
        }
    },
};
</script>

<style>



.import-progress-overlay {
    font-family: "Trebuchet MS", "Verdana";
    font-size: 24px;
    color: #32364e;
    border-radius: 16px;
}

.import-progress-overlay .p-dialog-content {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    text-align: center;
}

.import-progress-overlay .p-dialog-footer {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.edit-account-settings-modal {
    font-family: "Trebuchet MS", "Verdana";
    color: #32364e;
    border-radius: 16px;
    width: 435px;
}

.edit-account-settings-modal .p-dialog-content {
    border: none;
    text-align: center;
    color: #BFBFBF;
}

.edit-account-settings-modal .p-dialog-footer {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.account-selector-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #32364e;
    color: #FFFFFF;
}
.account-selector-dropdown-panel .p-dropdown-item.p-highlight .dropdown-option {
    color: #FFFFFF;
}
</style>

<style scoped>

::v-deep(*:not(.pi, .material-icons-outlined, .material-symbols-outlined)), ::v-deep(.p-component){
    font-family: 'Trebuchet MS', 'Verdana';
}

::v-deep(.optional-buttons-container .settings-button) {
    padding: 0.25rem 0.5rem;
}
::v-deep(.settings-button img) {
    width: 28px;
}

.account-dropdown-container {
    display: flex;
    align-items: center;
    position: relative;
}
.account-dropdown-container > .account-icon {
    position: absolute;
    z-index: 997;
}
.account-dropdown-container > .account-icon ::v-deep(.community-icon) {
    position: relative;
    width: 18px;
    left: 12px;
    top: 1px;
}
.account-dropdown-container > .account-icon ::v-deep(.your-portfolio-icon) {
    position: relative;
    width: 18px;
    height: 18px;
    left: 12px;
    top: 1px;
}
.account-dropdown-container > .account-icon ::v-deep(.connected-account-icon) {
    position: relative;
    width: 18px;
    left: 12px;
    top: 2px;
}
.account-dropdown-container > .account-icon ::v-deep(.manual-account-icon) {
    position: relative;
    width: 18px;
    left: 12px;
    top: 2px;
}
.account-dropdown-container > .account-icon ::v-deep(.competition-account-icon) {
    position: relative;
    width: 18px;
    left: 12px;
    top: 2px;
}
.account-dropdown-container > .account-icon ::v-deep(.cash-account-icon) {
    position: relative;
    width: 18px;
    left: 12px;
    top: 2px;
}
.account-selector ::v-deep(.p-dropdown-label) {
    padding-left: 24px;
}
.account-dropdown-item {
    display: flex;
    align-items: center;
}
.account-dropdown-item .account-icon {
    margin-right: 8px;
}
.account-dropdown-item .account-icon ::v-deep(.community-icon) {
    position: relative;
    top: 2px;
    width: 20px;
}
.account-dropdown-item .account-icon ::v-deep(.your-portfolio-icon) {
    position: relative;
    top: 2px;
    width: 20px;
    height: 20px;
}
.account-dropdown-item .account-icon ::v-deep(.connected-account-icon) {
    position: relative;
    top: 2px;
    width: 20px;
}
.account-dropdown-item .account-icon ::v-deep(.manual-account-icon) {
    position: relative;
    top: 2px;
    width: 20px;
    height: 18px;
}
.account-dropdown-item .account-icon ::v-deep(.competition-account-icon) {
    position: relative;
    top: 2px;
    width: 20px;
    height: 20px;
}
.account-dropdown-item .account-icon ::v-deep(.cash-account-icon) {
    position: relative;
    top: 2px;
    width: 20px;
}
.tab-div {
    position: relative;
}

.add-account-button, .delete-account-button, .retry-import-button {
    border-width: 2px;
}

.retry-import-button {
    border-color: #32364e;
}

.retry-import-button:hover, .retry-import-button:active {
    color: #fff;
    background-color: #32364e;
    border-color: #32364e;
}

.connect-account-button {
    font-weight: bold;
    font-size: 20px;
}

.connect-account {
    width: 100%;
    height: 100%;
    margin-top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-account-button {
    margin-right: 16px;
}

.first-connection-container {
    display: flex;
    flex-direction: column;
}

.account-import-text {
    text-align: center;
    font-size: 18px;
    color: #32364e;
}

.account-selector {
    margin-right: 16px;
}

::v-deep(.p-dropdown) {
    border: 2px solid #32364e;
    border-radius: 50px;
    padding: 1px 4px 1px 8px;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
}

::v-deep(.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus) {
    border-color: #32364e;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
}

::v-deep(.p-dropdown-label) {
    color: #32364e;
    font-size: 1rem;
    max-width: 250px !important;
}

::v-deep(.p-dropdown-trigger-icon) {
    color: #32364e;
    font-size: 0.9rem;
}

::v-deep(.p-dropdown-item) {
    color: #32364e;
    border-bottom: 1px solid #333;
}

.suspended,
.failed-import-item {
    color: #f80814 !important;
}

.waiting-import-item {
    color: #ffbd53 !important;
}

.import-error-message {
    color: #f80814;
    font-size: 16px;
    padding-top: 12px;
    display: block;
    white-space: pre-line;
}


.edit-account-settings-modal .p-dialog-footer button {
    border-radius: 50px;
    width: 50%;
}

.performers-wrapper {
    display: flex;
    align-items: center;
}

.performers-header-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;
}
.table-header {
    font-family: 'Trebuchet MS';
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    margin-bottom: 0;
    margin-right: 4px;
}

@media (max-width: 760px) {
    .tab-div {
        padding-left: 5px;
        padding-right: 5px;
    }

    ::v-deep(.p-dropdown-label) {
        max-width: 210px !important;
    }

    .account-selector {
        margin-right: 0px;
    }

    ::v-deep(.optional-buttons-container) {
        margin-left: 0 !important;
    }
    
    ::v-deep(.optional-buttons-container > *) {
        margin-right: 8px !important;
    }

    .pill-menu-bar{
        padding: 16px 8px !important;
    }
}

</style>
