<template>
    <div class="card">
      <div class="header-container">
        <h3 class="header">Cash Flow Chart</h3>
        <i class="pi pi-info-circle" v-tooltip.right="'This chart shows the expected cash flow for your portfolio and highlights the riskiness of each one. All values are converted to the account currency.'"></i>
      </div>
      <Chart type="bar" :data="chartData" :options="chartOptions" :height=350 :plugins="plugins" />
    </div>
</template>

<script>
import Chart from 'primevue/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: 'CashFlowChart',
  
  components: {
    Chart,
  },

  props: {
    chartData: {
      required: true,
    }
  },

  data () {
    return {
      plugins: [ChartDataLabels],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            color: '#000',
            font: {
              family: "Trebuchet MS",
              size: 10,
              weight: 'bold',
            },
            //anchor: 'end',
            align: 'top',
            formatter: (value, context) => {
              const datasetArray = [];
              context.chart.data.datasets.forEach((dataset) => {
                if (dataset.data[context.dataIndex] !== undefined) {
                  datasetArray.push(dataset.data[context.dataIndex]);
                } 
              });

              function totalSum(total, datapoint) {
                return total + datapoint;
              }

              let sum = datasetArray.reduce(totalSum, 0);
              let formattedSum = `$${sum.toFixed(2)}`;

              if(context.datasetIndex == datasetArray.length - 1) {
                if (sum > 0) {
                  return formattedSum;
                } else {
                  return '';
                }
              } else {
                return '';
              }
              
            }
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            }, 
            ticks: {
              font: {
                family: "Trebuchet MS",
              },
              color: '#999' 
            }
          },
          y: {
            stacked: true,
            grid: {
              borderDash: [1,5],
              drawBorder: false,
              tickColor: '#fff',
              color: '#BFBFBF',
              font: {
                family: 'Trebuchet MS',
                color: '#999'
              }
            },
            ticks: {
              padding: 8,
              font: {
                family: "Trebuchet MS"
              },
              color: '#999'
            }
          },
        }
      }
    }
  },

  methods: {
    getChartData() {
      this.testChartData.forEach((dataset) => {
          const dataSetObj = {
            label: dataset.label,
            data: dataset.values
          }
          return dataSetObj;
      })
    },
  }
}
</script>

<style scoped>
.header-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.header {
  font-size: 24px;
  color: #32364e;
  margin-bottom: 0px;
  margin-right: 8px;
}

.chart-container {
  height: 500px;
}
</style>