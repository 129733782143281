<template>
  <div class="stats-container">
    <div class="card stat">
      <h5>Yield:</h5>
      <p>{{valueFormatter(stats.divYield, 'percentage')}}</p>
    </div>
    <div class="card stat">
      <h5>Yield on Cost:</h5>
      <p>{{valueFormatter(stats.yieldOnCost, 'percentage')}}</p>
    </div>
    <div class="card stat">
      <h5>Annual Income:</h5>
      <p>{{valueFormatter(stats.annualIncome, 'currency')}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CashFlowStats',

  props: {
    stats: {
      required: true
    }
  },

  methods: {
    valueFormatter(value, type) {
      if (!value) return "-";
      if (type === 'currency') {
        const formattedValue = '$' + value.toLocaleString('en-US', {
          minimumFractionDigits: 2, 
          maximumFractionDigits: 2
        });
        return formattedValue;
      } else if (type === 'percentage') {
        const formattedValue = value.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + '%';
        return formattedValue;
      }
    }
  },
}
</script>

<style scoped>
.stats-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stat {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  margin-right: 16px;
}

.stat:last-child {
  margin-right: 0px;
}

.stat h5 {
  margin-bottom: 0px;
  margin-right: 8px;
  color: #32364e;
  font-size: 20px;
}

.stat p {
  font-size: 18px;
  color: black;
}
</style>