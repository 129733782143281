<template>
    <Dialog header='Add account' v-model:visible="display" :modal="true" :draggable="false" class='add-account-options-modal'>

        <p class='body-text' v-if="paperAccountsEnabled()">What type of account do you want to add?</p>

        <div class='buttons-container'>
            <div class="card" v-if="paperAccountsEnabled()">
                <Button label='Paper Trading Account' class='green-button__secondary' @click="onPaperTradingAccountButtonClick"/>
                <p>Paper Trading accounts allow you to place buy/sell orders for stocks using "Playdough".  The accounts are designed to mimick as much as possible real trading accounts.</p>
            </div>
            <div class="card" v-if="paperAccountsEnabled()">
                <Button label='Manual Account' class='purple-button__secondary' @click="onManualAccountButtonClick"/>
                <p>Manual accounts are an alternative to connecting your brokerage. You can replicate your portfolio by entering your holdings manually.</p>
            </div>
        </div>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import UserUtils from '../../../utilities/UserUtils';

import {PAPER_ANALYSIS_ACCOUNT_TYPE, PAPER_TRADING_ACCOUNT_TYPE} from '../../../common/constants';

export default {
    name: 'AddAccountOptionsModal',
    emits: ['create-paper-account', 'open-upgrade-to-premium-modal'],
    components: {
        Dialog
    },

    data() {
        return {
            PAPER_ANALYSIS_ACCOUNT_TYPE, 
            PAPER_TRADING_ACCOUNT_TYPE,

            display: false
        }
    },


    methods: {
         paperAccountsEnabled() {
            return UserUtils.isFeatureEnabled('paperAccountsEnabled');
        },
        open() {
            this.display = true;
        },

        onClose() {
            this.display = false;
        },

        onPaperTradingAccountButtonClick() {
            this.$emit('create-paper-account', PAPER_TRADING_ACCOUNT_TYPE["ID"]);
            this.onClose();
        },

        onManualAccountButtonClick() {
            this.onClose();

            if(UserUtils.isPremiumSubscriber()){
                this.$emit('create-paper-account', PAPER_ANALYSIS_ACCOUNT_TYPE["ID"]);
            } else {
                this.$emit('open-upgrade-to-premium-modal', true);
            }
        },
    },

}
</script>

<style>
.add-account-options-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 500px !important;
}

.add-account-options-modal .p-dialog-header {
    padding: 16px;
}

.add-account-options-modal .p-dialog-content {
    padding: 0 16px 16px;
    overflow-y: visible;
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}


@media (max-width: 760px) {
    .add-account-options-modal {
        left: 0!important;
        max-height: 100%;
        height: 100%;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
    }

    .add-account-options-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .add-account-options-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .add-account-options-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.card {
    width: 100% !important;
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.body-text {
    color: black;
    font-size: 1.1rem;
    margin-bottom: 16px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.buttons-container .card {
   border: 1px solid #32364E !important;
   box-shadow: none;
}

.p-button {
    border-radius: 16px;
    margin-bottom: 8px;
}
.p-button:last-of-type {
    margin-bottom: 0;
}

@media (max-width: 760px) {

}
</style>