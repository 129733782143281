<template>
    <div class="tab-content" v-if="canAccessFeature || isCommunityPortfolio">
        <LoadingSpinner :showLoading="!portfolioLoaded" style="width: 100%; height: 100%" text="Generating cash flow forecast and safety scores. This may take a bit."/>
        <div class="p-grid" v-if="portfolioLoaded">
          <div class="col p-col-12 p-md-12">
            <CashFlowChart :chartData="chartData" />
          </div>
          <div class="col p-col-12 p-md-12">
            <CashFlowStats :stats="portfolioStats" />
          </div>
          <div class="col p-col-12 p-md-12">
            <CashFlowTable :holdings="sortByAnnualIncome(holdings)" />
          </div>
        </div>
    </div>

    <UpgradeToPremiumModal ref='upgradeToPremiumModal' :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText='bodyText' @cancel-upgrade="cancelUpgrade" />
</template>

<script>
import LoadingSpinner from '../../components/common/LoadingSpinner.vue';
import CashFlowTable from './cash-flow/CashFlowTable.vue';
import CashFlowChart from './cash-flow/CashFlowChart.vue';
import CashFlowStats from './cash-flow/CashFlowStats.vue';
import UpgradeToPremiumModal from '../../components/modal/UpgradeToPremiumModal.vue';

import {PREMIUM_SUBSCRIPTION_TYPES} from '../../common/constants';
import PortfolioService from '../../service/PortfolioService';

import {mapState} from 'vuex';
//import UserUtils from '../../utilities/UserUtils';

export default {
  name: 'Portfolio-Cash-Flow',
  components: {
    LoadingSpinner,
    CashFlowTable,
    CashFlowChart,
    CashFlowStats,
    UpgradeToPremiumModal
  },

  props: {
      selectedAccountData: {
          required: true,
      },

      fullLoad: {
        required: true,
      },
  },

  data() {
    return {
      //CONSTANTS
      PREMIUM_SUBSCRIPTION_TYPES,

      bodyText: "To get access to the forecasted cash flow for your portfolio plus all the other premium tools, upgrade to premium and lock in the current low price for as long as you keep your subscription active.",
      portfolioLoaded: false,
      holdings: [],
      portfolioStats: {},
      chartData: {
        labels: [],
        datasets: null,
      }
    }
  },

  computed: {
    ...mapState(['users']),

    isCommunityPortfolio() {
      if( !this.selectedAccountData ) {
        return true;
      }
      else {
        return this.selectedAccountData?.investmentAccountId == -999;
      }
    },

    canAccessFeature() {
      return this.$store.getters['users/featureList'].includes('Cashflow') ;
    }
  },

  watch: {
    selectedAccountData(value) {
        if (!value) return;

        try {
            console.log('initializing cash flow from watcher');
            
            if (!this.canAccessFeature) {
              if (value?.investmentAccountId === -999) {
                this.initializeCashFlow();
              } else {
                this.$refs.upgradeToPremiumModal.open();
              }
            } else {
              this.initializeCashFlow();
            }
        } catch (err) {
            console.log(err);
        }
    },

    holdings(value) {
      this.chartData.datasets = value.filter(holding => {
        return holding.data?.length > 0;
      });

      this.chartData.datasets.sort((a) => {
        if (a.safety === 'HIGH') return -1;
        if (a.safety === 'MEDIUM') return 0;
        if (a.safety === 'LOW') return 1;
      });
    }
  },

  created() {
    this.initializeCashFlow();
  },
  mounted() {
    if (!this.canAccessFeature) {

      if (this.selectedAccountData && this.selectedAccountData?.investmentAccountId != -999) {
        this.$refs.upgradeToPremiumModal.open();
      }
      
    }
  },

  methods: {
    cancelUpgrade() {
      this.$router.push('/portfolios');
    },

    initializeCashFlow() {
      this.loadCashFlowData();
    },

    loadCashFlowData() {
      try {
        this.portfolioLoaded = false;
        PortfolioService.getCashFlowData(this.selectedAccountData?.investmentAccountId).then(cashFlowData => {
          if (cashFlowData.status === 200) {
            this.getDataFromCashFlow(cashFlowData.data);
            this.portfolioLoaded = true;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },

    sortByAnnualIncome(holdings) {
      return holdings.sort((a, b) => {
        return b.annualIncome - a.annualIncome;
      });
    },

    getDataFromCashFlow(data) {

      this.holdings = [];
      this.portfolioStats = {};

      if (data.status != 'success') return;

      const forecastData = data.forecast;
      const assetForecasts = forecastData.assetDividendForecasts;

      // set props for portfolio stats component
      this.portfolioStats.divYield = forecastData.ntmYield  * 100;
      this.portfolioStats.yieldOnCost = forecastData.ntmYieldOnCost * 100;
      this.portfolioStats.annualIncome = forecastData.ntmAnnualIncome;

      // set props for chart and table
      assetForecasts.forEach(holding => {
        const holdingObject = {};

        holdingObject.symbol = holding.symbol;
        holdingObject.label = holding.symbol;
        holdingObject.name = holding.securityPosition.securityListing?.company || holding.securityPosition.fund?.name || holding.securityPosition.otherAsset?.name || holding.securityPosition.derivative?.name || '-';

        // handle no dividend case 
        if (holding.dividendCalendar.length === 0) {
          holdingObject.divYield = 0;
          holdingObject.yieldOnCost = 0;
          holdingObject.annualIncome = 0;
          holdingObject.safety = '-';
          holdingObject.dividendSafetyFactors = null;

          this.holdings.push(holdingObject);
          return;
        }

        if (this.chartData.labels.length === 0) {
          // cut div calendar down to just be 1 year in the future
          const updatedCalendar = holding.dividendCalendar;//.slice(12);

          updatedCalendar.forEach(calendar => {
            const date = new Date(calendar.date);
            const dayNumber = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear().toString();
            const formattedYear = year.slice(-2);

            const formattedDate = `${month}/${dayNumber}/${formattedYear}`;

            this.chartData.labels.push(formattedDate);
          })          
        }

        // handle dividend case
        holdingObject.divYield = holding.ntmYield * 100;
        holdingObject.yieldOnCost = holding.ntmYieldOnCost * 100;
        holdingObject.annualIncome = holding.ntmAnnualIncome;
        holdingObject.safety = holding.dividendSafety;
        if (holding.dividendSafety === 'HIGH') {
          holdingObject.backgroundColor = 'rgba(51, 204, 153, 0.8)';
        } else if (holding.dividendSafety === 'MEDIUM') {
          holdingObject.backgroundColor = 'rgba(255, 189, 83, 0.8)';
        } else if (holding.dividendSafety === 'LOW') {
          holdingObject.backgroundColor = 'rgba(230, 62, 62, 0.8)';
        }
        holdingObject.dividendSafetyFactors = holding.dividendSafetyFactors;

        // cut first year of div calendar data
        const updatedData = holding.dividendCalendar;//.slice(12);

        holdingObject.data = updatedData.map(month => {
          return Number(month.value.toFixed(2));
        });

        this.holdings.push(holdingObject);
        return;
      })
    }
  }
}
</script>

<style scoped>
.tab-content {
  padding-top: 0px;
}

.banner {
  text-align: center;
  color: #32364e;
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 10px 20px;
}

.banner-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
</style>