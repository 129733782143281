<template>
    <Dialog header='Score Details' v-model:visible="display" :modal="true" :draggable="false" class='score-details-modal' @hide="onHide">
        <span>Score: <span :class="valueClass(assetDividendsForecast.score)">{{ assetDividendsForecast.score }}</span></span>
        
        <span v-if="assetDividendsForecast.dividendPayoutRatio">Dividend Payout Ratio: {{formatter.format(assetDividendsForecast.dividendPayoutRatio, '0.00')}}</span>
        
        <div class="dividends-container" v-if="assetDividendsForecast.cutDividendResult.numCutDividends > 0">
            <span>Most Recent Cut Start Date: {{ timeUtils.getFormattedTime(assetDividendsForecast.cutDividendResult.mostRecentCut.startDate, "YYYY/MM/DD") }}</span>
            <span>Most Recent Cut End Date: {{ timeUtils.getFormattedTime(assetDividendsForecast.cutDividendResult.mostRecentCut.endDate, "YYYY/MM/DD")  }}</span>
            <span>Number of Cut Dividends: {{ assetDividendsForecast.cutDividendResult.numCutDividends}}</span>
        </div>

        <div class="dividends-container" v-if="assetDividendsForecast.missedDividendResult.numMissedDividends > 0">
            <span>Most Recent Gap Start Date: {{ timeUtils.getFormattedTime(assetDividendsForecast.missedDividendResult.mostRecentGap.startDate, "YYYY/MM/DD")  }}</span>
            <span>Most Recent Gap End Date: {{ timeUtils.getFormattedTime(assetDividendsForecast.missedDividendResult.mostRecentGap.endDate, "YYYY/MM/DD")  }}</span>
            <span>Number of Missed Dividends: {{ assetDividendsForecast.missedDividendResult.numMissedDividends}}</span>
        </div>

        <span>Number of Years of Dividends: {{ assetDividendsForecast.yearsOfDividends }}</span>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

import FormattingUtils from '../../../utilities/FormattingUtils';
import TimeUtils from '../../../utilities/TimeUtils';

export default {
    name: 'ScoreDetailsModal',
    components: {
        Dialog
    },

    data() {
        return {
            display: false,

            formatter: new FormattingUtils(),
            timeUtils: TimeUtils,
            assetDividendsForecast: {}
        }
    },


    methods: {
        open(data) {
            this.assetDividendsForecast = data;
            this.display = true;
        },

        onHide() {
            this.assetDividendsForecast = {};
        },

        valueClass(score) {
            return [
                {
                'high': score === 'HIGH',
                'medium': score === 'MEDIUM',
                'low': score === 'LOW',
                }
            ];
        },
    },

}
</script>

<style>
.score-details-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 500px;
}

.score-details-modal .p-dialog-header {
    padding: 16px;
}

.score-details-modal .p-dialog-content {
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px;
    overflow-y: visible;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}


@media (max-width: 760px) {
    .score-details-modal {
        left: 0!important;
        max-height: 100%;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
    }

    .score-details-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .score-details-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .score-details-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-content > * {
    font-weight: bold;
    color: #32364e;
    margin-bottom: 12px;
}
.p-dialog-content > *:last-child {
    margin-bottom: 0;
}


.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.dividends-container {
    display: flex;
    flex-direction: column;
}

.high {
  color: #33CC99;
}

.medium {
  color: #FFBD53;
}

.low {
  color: #E63E3E;
}
</style>